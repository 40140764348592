/* UserDetail.css */
.user-detail-panel {
    background-color: #f7f7f7;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  
.photo {
    width: 100px;
    height: 100px;
}
.photo-container {
    text-align: center;
    margin: 20px 0;

}